<template>
  <div>
    <b-img-lazy :src="data.image_qrcode" fluid></b-img-lazy>
    <p>ID: <strong>{{data.token}}</strong></p>
    <p>Merchant: <strong>{{data.customer}}</strong></p>
    <p>Order ID: <strong>{{data.orderid}}</strong></p>
    <p>Price: <strong>{{ convertPrice }} THB</strong></p>
  </div>
</template>

<script>
export default {
  name: "DetailQrcode",
  props: ['data'],
  computed: {
    convertPrice(){
      return this.data && this.data.price && !isNaN(this.data.price) ? this.data.price.toFixed(2) : 0.00
    },
  }
}
</script>

<style scoped>

</style>
