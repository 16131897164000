<template>
  <div>
    <b-img-lazy center width="125px" height="125px" :style="logo_bank.css" rounded :src="logo_bank.image"></b-img-lazy>
    <p>ID: <strong>{{data.token}}</strong></p>
    <p>Merchant: <strong>{{data.customer}}</strong></p>
    <p>Order ID: <strong>{{data.orderid}}</strong></p>
    <p>Bank: <strong>{{ data.accountbank.type }}</strong></p>
    <p>Account: <strong>{{ data.accountbank.account }}</strong><b-button size="sm" v-clipboard:copy="data.accountbank.account">Copy</b-button></p>
    <p>Name: <strong>{{ data.accountbank.name }}</strong></p>
    <p>Price: <strong>{{ convertPrice }} THB</strong></p>
  </div>
</template>

<script>
import typebank from "@/util/typebank";
export default {
  name: "DatailBanktransfer",
  props: ['data'],
  computed: {
    datatype_bank(){
      return typebank()
    },
    convertPrice(){
      return this.data && this.data.price && !isNaN(this.data.price) ? this.data.price.toFixed(2) : 0.00
    },
    logo_bank(){
      const findIndex = this.datatype_bank.findIndex(value => value.key === this.data.accountbank.type.toLowerCase())
      return {
        image: require(`../../assets/iconbank/${this.data.accountbank.type.toLowerCase()}.svg`),
        css: {
          'background-color': this.datatype_bank[findIndex].color,
          'margin-bottom': '20px'
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
