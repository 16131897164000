import bank from '../banks.json'
export default () => {
    const result = []
    for(const x of Object.keys(bank.th)){
        const Model = {
            name: bank.th[x].nice_name,
            key: x,
            color: bank.th[x].color
        }
        result.push(Model)
    }
    return result
}
